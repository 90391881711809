'use client';
import Link from 'next/link';
import Logo from '@/assets/logo.png';
import Image from 'next/image';
import Config from '@/constants/Config';
import { Button } from '@/components/ui/button';
import { AlignLeft } from 'lucide-react';
import { usePrefStore } from '@/store/prefStore';
import Sheet from '@/components/Sheet/Sheet';
import { Nav } from '../Sidebar/Nav';
import navigationMenu from '../Sidebar/navigation-menu';
import { useViewportSize } from '@mantine/hooks';
import { RiMenuFold4Line, RiMenuUnfold4Line } from 'react-icons/ri';
import Avatar from './Avatar';
import Routes from '@/constants/Routes';
import useAuth from '@/hooks/useAuth';
import React, { useEffect } from 'react';
// import Notification from './Notification';

const Header = () => {
    const { isAuthenticated, logout, update } = useAuth();
    const { setShowSidebar, showSidebar, setShowMobileMenu, showMobileMenu } = usePrefStore();
    const { width } = useViewportSize();
    useEffect(() => {
        const visibilityHandler = () => {
            update();
        };
        window.addEventListener('visibilitychange', visibilityHandler, false);
        return () => window.removeEventListener('visibilitychange', visibilityHandler, false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <header className='sticky top-0 z-50 bg-background shadow' dark-mode='dark'>
            <div className='top-0 z-50 w-full items-center justify-center bg-background px-3 py-2.5 dark:border-gray-700 dark:bg-gray-800'>
                <div className='flex flex-wrap items-center justify-between'>
                    <div className='flex items-center justify-center gap-x-3 px-3'>
                        <Link href={Routes.DASHBOARD} className='hidden sm:flex'>
                            <Image src={Logo} alt='Logo' width={40} height={40} />
                            <span className='flex items-center whitespace-nowrap text-center text-lg font-semibold dark:text-white'>
                                {Config.APP_NAME}
                            </span>
                        </Link>
                        {isAuthenticated && (
                            <>
                                {width < Config.MOBILE_BREAK_POINT ? (
                                    <Sheet
                                        maxWidth='fit'
                                        side='left'
                                        content={<Nav links={navigationMenu} />}
                                        sheetProps={{
                                            open: showMobileMenu,
                                            onOpenChange: () => setShowMobileMenu(!showMobileMenu),
                                        }}>
                                        <Button
                                            variant='ghost'
                                            size='icon'
                                            aria-label='Toggle sidebar'>
                                            <AlignLeft className='h-5 w-5' />
                                        </Button>
                                    </Sheet>
                                ) : (
                                    <Button
                                        className='hidden'
                                        variant='ghost'
                                        size='icon'
                                        aria-label='Toggle sidebar'
                                        onClick={() => setShowSidebar(!showSidebar)}>
                                        {showSidebar ? (
                                            <RiMenuFold4Line className='text-2xl' />
                                        ) : (
                                            <RiMenuUnfold4Line className='text-2xl' />
                                        )}
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                    {isAuthenticated && (
                        <div className='flex items-center justify-center space-x-3'>
                            {/* <Notification /> */}
                            <Avatar />
                            <Button variant='outline' className='' onClick={logout}>
                                Log out
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
